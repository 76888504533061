import React from "react"
import $ from "jquery"
import emailjs from 'emailjs-com'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Map from "../components/map"

import { IconContext } from "react-icons"
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelopeOpenText, FaPaperPlane } from "react-icons/fa"

import contactStyles from "../styles/pages/contact.module.css"

const ContactPage = () => {

  function sendEmail(e) {
    e.preventDefault();
    emailjs.sendForm('teamplay_mail_service', 'teamplay_email_template', e.target, 'user_4xjNXZ0kXjK7KjzFfTQYE')
			.then(function(response) {
			  $("#email-sent").addClass(contactStyles.in);
        $("#error-sending").removeClass(contactStyles.in);
        $("#message-form")[0].reset();
        setTimeout(function() {$("#email-sent").removeClass(contactStyles.in);}, 5000);
			}, function(error) {
				$("#email-sent").removeClass(contactStyles.in);
        $("#error-sending").addClass(contactStyles.in);
        $("#message-form")[0].reset();
        setTimeout(function() {$("#error-sending").removeClass(contactStyles.in);}, 5000);
      });
  }

  return (
    <Layout location="contact">
      <SEO title="Contact Us" />
      <div className={ contactStyles.grid }>
        <div className={ contactStyles.left }>
          <div className={ contactStyles.contactFormHeader }>
            <h1>Send us a message.</h1>
            <h6>You'll definitely find success working with us.</h6>
          </div>
          <section className={ contactStyles.contactForm }>
            <form id="message-form" onSubmit={ sendEmail }>
                <div className={ contactStyles.inlineFields }>
                  <div className={ contactStyles.formGroup }>
                    <label htmlFor="firstNameField">First Name</label>
                    <input type="text" name="first_name" id="firstNameField" required={ true } />
                  </div>
                  <div className={ contactStyles.formGroup }>
                    <label htmlFor="lastNameField">Last Name</label>
                    <input type="text" name="last_name" id="lastNameField" required={ true } />
                  </div>
                </div>
                <div className={ contactStyles.formGroup }>
                  <label htmlFor="emailField">Email</label>
                  <input type="email" name="email" id="emailField" required={ true } />
                </div>
                <div className={ contactStyles.formGroup }>
                  <label htmlFor="subjectField">Subject</label>
                  <input type="text" name="subject" id="subjectField" required={ true } />
                </div>
                <div className={ contactStyles.formGroup }>
                  <label htmlFor="messageField">Message</label>
                  <textarea name="message" id="messageField" rows="6" required={ true }></textarea>
                </div>
                <button type="submit">
                  Send
                </button>
            </form>
          </section>
        </div>
        <div className={ contactStyles.right }>
          <Map />
          <section className={ contactStyles.contactInfo }>
            <div>
              <div className={ contactStyles.ourOffice }>
                <div>
                  <IconContext.Provider value={{ className: contactStyles.icon }}>
                    <FaMapMarkerAlt />
                  </IconContext.Provider>
                  <h5>Our Location</h5>
                </div>
                <p><strong>TEAMPLAY</strong></p>
                <p>The IMC Block, 3rd Floor</p>
                <p>141 Scout Rallos Extension</p>
                <p>Sacred Heart, Quezon City, Metro Manila 1103 PH</p>
              </div>
              <div className={ contactStyles.trunkLines }>
                <div>
                  <IconContext.Provider value={{ className: contactStyles.icon }}>
                    <FaPhoneAlt />
                  </IconContext.Provider>
                  <h5>Trunk Line</h5>
                </div>
                <p>(02) 3448-5386</p>
              </div>
              <div className={ contactStyles.emails }>
                <div>
                  <IconContext.Provider value={{ className: contactStyles.icon }}>
                    <FaEnvelopeOpenText />
                  </IconContext.Provider>
                  <h5>Email</h5>
                </div>
                <p>teamplayexec@yahoo.com</p>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
}

export default ContactPage